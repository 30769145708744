@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Roboto+Slab:wght@300;400;500;600&display=swap');


/* For mobile phones: */
.app {
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .app {
    background-color: #FBFBFB;

  }
}

.content {
  padding-top: 3em;
  padding-bottom: 5em;
  margin-left: 1%;
  margin-right: 1%;
}
.content .contentBox {
  margin-left: 5em;
  margin-right: 5em;
}
.content iframe {
  margin-top: 1em;
  width: 100%;
  height: 400px;
}



.content p {
  font-size: 20px;
  line-height: 1.5em;
  margin-left: 3em;
  margin-right: 3em;

}
.content img {
  margin-left: auto;
  margin-right: auto;
  display: inline-flex;
  width: 100%;
}
.content ul {
  font-size: 20px;
  line-height: 1.5em;
  margin-left: 3em;
  margin-right: 3em;
}
.content h1 {
  font-size: 4em;

}
.content h2 {
  font-size: 2em;

}

.content img {
  width: 100%;
}
.landing {

}

.title {

  margin-bottom: 20px;



  font-size: 130px;

  float: right;

  display: flex-inline;

}

.title .name {


  /* Quinn Voronin */
  width: 100%;

  padding-right: .1em;

  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: auto;
  line-height: 1.2em;


  background-color: #191D32;
  color: #FBFBFB;


  /* identical to box height */
  float: right;
  text-align: center;
}

.title::after{
  display: inline;
}


.title .job {


  /* Inventor */
  margin-left: 7em;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: .5em;
  line-height: 1em;

  background-color: #F3CA40;
  color: #101010;


  /* identical to box height */
  align-items: left;
  text-align: center;
}


.title .tagline {


  /* building solutions for the future */
  padding: .1em;

  font-family: 'Roboto Slab', serif;
  font-style: normal;
  font-weight: 800;
  font-size: .25em;
  line-height: 1em;

  color: #101010;


  /* identical to box height */
  align-items: center;
  text-align: right;
}


.title .seemore {


  background: #F3CA40;
  border: 5px solid #101010;
  box-sizing: border-box;
  border-radius: 77px;

  font-family: 'Roboto Slab', serif;
  font-style: normal;
  font-weight: 600;
  font-size: .2em;
  line-height: auto;

  float: right;
  align-items: center;
  text-align: center;
}

.landing img {

  float: right;
  object-fit: cover;

  width: 97%;
  height: auto;
}
.nav {
  position: fixed;
  display: inline;
  align-items: center;
  text-align: center;

  width: 100%;

}

.nav-open {
  animation-name: navCollapse;
  animation-duration: .5s;
  animation-direction: reverse;

  transform: translate3d(0px, 0%, 0px);

  display: flex;
  height: 25%;


  background: #191D32;

  font-size: 24px;

  display: flex;

  margin-bottom: 2em;
}

.nav-closed {

  animation-name: navCollapse;
  animation-duration: .5s;
  animation-direction: normal;

  transform: translate3d(0px, -80%, 0px);

  display: flex;

  background: #191D32;

  font-size: 24px;

  margin-bottom: 2em;
}



@keyframes navCollapse {
  0%    {transform: translate3d(0px, 0px, 0px);}
  100%  {transform: translate3d(0px, -80%, 0px);}
}

.nav ul {

  text-align: center;
  list-style-type: none;
  align-items: center;
  display: flex;

  width: 50%;


  margin: 0em;
}


.nav ul li {


  width: 25%;
  margin-left: .5em;
  margin-right: .5em;



}

.nav ul li a:link {
  text-decoration: none;
  color: #101010;

}
.nav ul li a:visited {
  text-decoration: none;
  color: #101010;

}
.nav ul li a:hover {
  background-color: #d1d1d1;
}

.nav ul li a {



  display: block;
  background-color: #FBFBFB;
  display: block;

  border: none;
  border-radius: 2px;

  font-size: 1em;
  line-height: 1em;



  padding-top: .5em;
  padding-bottom: .5em;
  padding-left: .5em;
  padding-right: .5em;

}

.nav .wordmark a:link{
  color: #FBFBFB;
  display: inline;
  text-decoration: none;

  font-size: 1.5em;
  line-height: 1.5em;

  margin-top: .5em;
  margin-bottom: .5em;
  margin-left: 1em;
  margin-right: 1em;

  float: left;

  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: auto;
  line-height: 1.2em;


}

.nav .wordmark a:visited{
  color: #FBFBFB;

}

.nav hr {
    border-top: 2px solid #101010;
    float: left;

}

.navLinks .links {
  width: 10%;

}

.media {
  width: 10%;
}

.contact-me {
  background: #F3CA40;

  display: block;
  text-align: center;
  float: right;

  border: none;
  border-radius: 2px;

  font-size: 2em;
  line-height: 1em;
  color: #101010;

  padding: .75em;

  margin-bottom: .5em;
  margin-top: .5em;

  margin-left: 5%;
  margin-right: 5%;

  width: 15%;
}
.contact-me a:link {
  text-decoration: none;
  color: #101010;

}
.contact-me a:visited {
  text-decoration: none;
  color: #101010;

}

.media img{
  width: 12.5%;
  height: auto;
  border-radius: 100px;
  background-color: #000;
  margin: .5em;

}

.bottom{
  position: fixed;
  bottom: 0;
  width: 100%;
}
